<template>
  <div>
    <header-slot>
      <template #actions>
        <div>
          <template v-if="$route.name == 'adm-register-payments-automatic'">
            <b-button
              class="mr-1"
              variant="success"
              :to="{ name: 'adm-history-imports' }"
            >
              <tabler-icon icon="DiscIcon" style="margin-right: 5px" />
              <span> History Imports </span>
            </b-button>
            <b-button
              variant="primary"
              :to="{ name: 'adm-history-automatic-import' }"
            >
              <tabler-icon icon="ArrowBarDownIcon" style="margin-right: 5px" />
              <span>Imports</span>
            </b-button>
          </template>
          <template
            v-if="
              ['adm-history-imports', 'adm-manual-process-payment', 'adm-history-automatic-import'].includes(
                $route.name
              )
            "
          >
            <b-button
              variant="danger"
              :to="{
                name:
                  $route.name == 'adm-history-imports' || $route.name == 'adm-history-automatic-import'
                    ? 'adm-register-payments-automatic'
                    : 'adm-register-payments-manual',
              }"
            >
              <tabler-icon icon="ArrowLeftIcon" style="margin-right: 5px" />
              <span>Return</span>
            </b-button>
          </template>
          <template v-if="$route.name == 'adm-register-payments-manual'">
            <b-button
              variant="primary"
              :to="{ name: 'adm-manual-process-payment' }"
            >
              <tabler-icon icon="PlusIcon" style="margin-right: 5px" />
              <span>Process</span>
            </b-button>
          </template>
        </div>
      </template>
    </header-slot>

    <b-nav header pills class="m-0 card-h1">
      <!-- Tab AUTOMATIC -->
      <b-nav-item
        :to="{ name: 'adm-register-payments-automatic' }"
        exact
        exact-active-class="active"
        :active="isActiveTab(1)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Automatic
      </b-nav-item>

      <!-- TAB MANUAL -->
      <b-nav-item
        :to="{ name: 'adm-register-payments-manual' }"
        exact
        exact-active-class="active"
        :active="isActiveTab(2)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Manual
      </b-nav-item>

      <!-- TAB OTHERS -->
      <b-nav-item
        :to="{ name: 'adm-register-payments-others' }"
        exact
        exact-active-class="active"
        :active="isActiveTab(3)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Others
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {
  computed: {
    routerName() {
      return this.$route.matched[2].name;
    },
  },
  methods: {
    isActiveTab(tab) {
      if (tab == 1 && this.routerName == "adm-register-payments-automatic") {
        return true;
      } else if (
        tab == 2 &&
        this.routerName == "adm-register-payments-manual"
      ) {
        return true;
      } else if (tab == 3 && this.routerName == "adm-register-payments-others")
        return true;
    },
  },
};
</script>

<style>
</style>