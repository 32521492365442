var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-slot',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',[(_vm.$route.name == 'adm-register-payments-automatic')?[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"success","to":{ name: 'adm-history-imports' }}},[_c('tabler-icon',{staticStyle:{"margin-right":"5px"},attrs:{"icon":"DiscIcon"}}),_c('span',[_vm._v(" History Imports ")])],1),_c('b-button',{attrs:{"variant":"primary","to":{ name: 'adm-history-automatic-import' }}},[_c('tabler-icon',{staticStyle:{"margin-right":"5px"},attrs:{"icon":"ArrowBarDownIcon"}}),_c('span',[_vm._v("Imports")])],1)]:_vm._e(),(
            ['adm-history-imports', 'adm-manual-process-payment', 'adm-history-automatic-import'].includes(
              _vm.$route.name
            )
          )?[_c('b-button',{attrs:{"variant":"danger","to":{
              name:
                _vm.$route.name == 'adm-history-imports' || _vm.$route.name == 'adm-history-automatic-import'
                  ? 'adm-register-payments-automatic'
                  : 'adm-register-payments-manual',
            }}},[_c('tabler-icon',{staticStyle:{"margin-right":"5px"},attrs:{"icon":"ArrowLeftIcon"}}),_c('span',[_vm._v("Return")])],1)]:_vm._e(),(_vm.$route.name == 'adm-register-payments-manual')?[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'adm-manual-process-payment' }}},[_c('tabler-icon',{staticStyle:{"margin-right":"5px"},attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Process")])],1)]:_vm._e()],2)]},proxy:true}])}),_c('b-nav',{staticClass:"m-0 card-h1",attrs:{"header":"","pills":""}},[_c('b-nav-item',{attrs:{"to":{ name: 'adm-register-payments-automatic' },"exact":"","exact-active-class":"active","active":_vm.isActiveTab(1),"link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Automatic ")]),_c('b-nav-item',{attrs:{"to":{ name: 'adm-register-payments-manual' },"exact":"","exact-active-class":"active","active":_vm.isActiveTab(2),"link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Manual ")]),_c('b-nav-item',{attrs:{"to":{ name: 'adm-register-payments-others' },"exact":"","exact-active-class":"active","active":_vm.isActiveTab(3),"link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v(" Others ")])],1),_c('b-card',{staticClass:"border-top-primary border-3 border-table-radius px-0",attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }